/*
@media screen and (max-aspect-ratio: 1/1) {
    .scroll{
	height: 400px;
    }
}
@media screen and (min-aspect-ratio: 1/1) {
    .srcoll{
	height: 200px;
    }
}
*/
.thumb{
    height: 100%;
}

.scroll{
    overflow-y: auto;
}
.random{
    height: 100%;
}
