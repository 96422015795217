.shirt-container{
    position: fixed;
    margin-right: 20px;
    top: 0;
    display: flex;
    height: 100%;
    align-items: center;
		
}
ion-input{

}
