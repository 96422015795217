#map{
    height:95%;
    min-height: 350px;
}
.big-button{
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.hover-button{
    width: 100%;
    text-align: right;
}
.hover-button>ion-button,ion-router-link{
    margin: 1rem;
    position:absolute;
    z-index: 2;
}
#location-buttons{
    position:absolute;
    z-index: 1;
    background-color: transparent;
    bottom:0;
}
.transparent{
    background-color: transparent;
}
#shirt-img-container{
    text-align: center;    
}
